<template>
	<div class="resources">
		<div class="resources-content" :style="{ width: bg_width + 'px', left: bg_left + 'px' }">
			<img :src="background_url" class="bg" ref="bg" @load="bg_loaded()" draggable="false">
			<div class="library" :class="app.day_moment"></div>

			<div class="element research-papers" v-hammer:tap="() => goto({ name: 'resources-research-papers' })">
				<div class="npc npc-profile-1" id="npc-profile-1"></div>
				<div class="element-title" v-html="$t('research_papers')"></div>
			</div>
			<div class="element archives" v-hammer:tap="() => display_patients()">
				<div class="npc npc-profile-2" id="npc-profile-2"></div>
				<div class="element-title">{{ caps($t('archives')) }}</div>
			</div>

		</div>

		<div class="home-button" v-hammer:tap="()=> goto({ name: 'home' })"></div>
		<div class="room-title">{{ caps($t('the_staff_room')) }}</div>

		<transition name="research-papers">
			<research-papers v-if="$route.name === 'resources-research-papers'" />
		</transition>

		<transition name="dialog-box">
			<patients
				v-if="patients.patients_visible"
				:scopes="[ 'completed' ]"
			/>
		</transition>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ResearchPapers from '@/components/resources/research-papers'
import Patients from '@/components/patients/patients'
import {manageEventListeners} from "@/js/managers/manageEventListeners"
import {manageLottie} from "@/js/managers/manageLottie"
import { capitalizeOnLanguage } from '@/js/utils/utils'
import { LANGUAGE } from '@/js/factory/language'

export default {
	name: 'resources',
	data() {
		return {
			bg_width: 0,
			bg_left: 0,
			animations: [],
			manageEventListeners: manageEventListeners()
		}
	},
	computed: {
		...mapGetters([ 'app', 'patients' ]),
		background_url() {
			return this.$medias.find({ tags: [ 'background', this.app.day_moment, 'floor-1' ] })
		},
	},
	methods: {
		caps(value) {
			return capitalizeOnLanguage(['en'], LANGUAGE.getData(), value)
		},
		bg_loaded() {
			this.resize()
		},
		goto(data) {
			this.$router.push(data)
		},
		resize() {
			if (this.$refs.bg) {
				setTimeout(() => {
					this.bg_width = this.$refs.bg.clientWidth
					this.bg_left = (window.innerWidth - this.$refs.bg.clientWidth) / 2
					this.$emit('loaded')
				}, 100)
			}
		},
		display_patients() {
			this.$store.dispatch('set_patients_visible', { patients_visible: true })
		},
	},
	mounted() {
		this.manageEventListeners.add(window, 'resize', this.resize)

		let file = 'NPC_Library_Black_Hair_Girl'
		if (this.app.day_moment === 'night') file = 'NPC_Library_Night_Black_Hair_Girl'

		this.animations.push(
			manageLottie.create({
				container: this.$el.querySelector('#npc-profile-1'),
				path: this.$medias.findBySlug({ slug: file }),
				renderer: 'svg',
				loop: true,
				autoplay: true,
				rendererSettings: {
					preserveAspectRatio: 'xMidYMax slice',
				}
			}),
		)
		this.animations.push(
			manageLottie.create({
				container: this.$el.querySelector('#npc-profile-2'),
				path: this.$medias.findBySlug({ slug: 'NPC_Library_Brown_Hair_Girl' }),
				renderer: 'svg',
				loop: true,
				autoplay: true,
				rendererSettings: {
					preserveAspectRatio: 'xMidYMax slice',
				}
			})
		)
	},
	components: { ResearchPapers, Patients },
	beforeDestroy() {
		this.animations.length = 0
		this.manageEventListeners.destroy()
	}
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'


#app.mobile
	.resources
		.bg
			transform translateX(-63%)
		.library
			left 54%
		.resources-content
			.element
				// background-color alpha(red, 50%)
				&.research-papers
					left 41%
					bottom 19.8%
					width 7.7%
					height 43%
					.element-title
						top -32%
						right -15%
						width 130%
						height 35%
						background-image url(../assets/img/bubble-research-papers-reverse.svg)
						padding 3% 11% 27% 11%
						line-height 1em
				&.archives
					left 48%
					bottom 18%
					width 13%
					height 50%
					.element-title
						background-image url(../assets/img/bubble-archives-reverse.svg)
						left 25%
						top -20%
						// padding-bottom 28px
						line-height 1em

#app.tablet
	.resources
		.bg
			transform translateX(-61%)
		.library
			left 62%
		.resources-content
			.element
				&.research-papers
					left 43%
					bottom 19.8%
					width 7.7%
					height 43%
					.element-title
						width 120%
						height 32%
						padding 5% 13% 20% 5%
						line-height 1em
				&.archives
					left 57%
					bottom 18%
					width 13%
					height 50%
					.element-title
						background-image url(../assets/img/bubble-archives-reverse.svg)
						left 0
						top -16%
						line-height 1em

.resources
	absolute 0
	flex center center
	.resources-content
		absolute 0
		.bg
			position absolute
			left 50%
			bottom 0
			height 100%
			transform translateX(-50%)
		.library
			position absolute
			left 72%
			bottom 23%
			width 25%
			height 60%
			background url(../assets/img/resources-library.svg) bottom left no-repeat
			background-size 100%
			&.night
				background-image url(../assets/img/resources-library-night.svg)
		.element
			position absolute
			cursor pointer
			&.research-papers
				left 54%
				bottom 19.8%
				width 7.7%
				height 43%
				.element-title
					top -10%
					right 100%
					// padding 8px 16px 26px 8px
					padding 2% 8% 17% 2%
					width 100%
					height 26%
					background-image url(../assets/img/bubble-research-papers.svg)
			&.archives
				left 68%
				bottom 18%
				width 13%
				height 50%
				.npc
					transform scaleX(-1)
				.element-title
					top -13%
					left 68%
					// padding 8px 8px 32px 8px
					padding 2% 2% 13% 2%
					background-image url(../assets/img/bubble-archives.svg)
					width 50%
					height 20%
			.npc
				absolute 0
			.element-title
				position absolute
				flex center center
				background center center no-repeat
				background-size contain
				text-align center
				color #fff

	.home-button
		position absolute
		right 16px
		top 16px
		width 40px
		height 40px
		background yellow url(../assets/img/home.svg) center center no-repeat
		background-size 24px 24px
		cursor pointer
		box-shadow 0px 3px 6px alpha(#000, 30%)
		&:before
			content ''
			absolute -8px
		&:hover
			background-color darken(yellow, 10%)
		&:active
			transform translateY(3px)
			background-color darken(yellow, 10%)
			box-shadow 0px 0px 0 alpha(#000, 30%)

	.room-title
		position absolute
		right 64px
		top 16px
		height 40px
		width 200px
		padding 0 16px 0 0
		background red
		cursor default
		user-select none
		box-shadow 0px 3px 6px alpha(#000, 30%)
		color #fff
		text-align right
		line-height @height
		border-radius 0 0 0 20px



</style>
