<template>
	<div class="papers">
		<div class="tab-content" v-scrollbar>
			<div>
				<div v-if="papers.length" class="papers-list">
					<paper v-for="paper in papers" :key="paper.id" :paper="paper" />
					<div v-show="show_more_visible" class="show-more">
						<div class="button" v-hammer:tap="show_more">{{ $t('show_more') }}</div>
					</div>
				</div>
				<div v-else class="no-papers">
					<div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg></div>
					<p>{{ $t('no_research_papers') }}</p>
				</div>
			</div>
		</div>
		<div v-show="loading" class="loading"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import Paper from '@/components/resources/research-papers/paper'

export default {
	name: 'papers',
	data() {
		return {
			loading: true,
			show_more_visible: false
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		papers() {
			if (this.app.research_papers) {
				return this.app.research_papers.map((p) => p.file)
			}
			return []
		}
	},
	watch: {
		papers() {
			this.show_more_visible = true
			if (this.app.research_papers_meta) {
				if (this.papers.length >= this.app.research_papers_meta.count) this.show_more_visible = false
			}
		}
	},
	methods: {
		goto(data) {
			this.$router.push(data)
		},
		async show_more() {
			this.loading = true
			await this.$store.dispatch('search_research_papers', { page: this.app.research_papers_meta.page + 1 })
			this.loading = false
		}
	},
	async created() {
		this.loading = true
		await this.$store.dispatch('search_research_papers')
		this.loading = false
	},
	components: { Paper }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.papers
	darkblue = #61789E
	.tab-content
		height 100%
		background-color #fff

		.papers-list
			padding 16px
			.paper
				flex center flex-start
				padding 16px
				margin 0 0 16px 0
				border 2px solid #FF9677
				border-top-width 16px
				&:last-child
					margin-bottom 0
				.left
					width 80px
					padding-right 16px
					font-size 1rem
					.img
						width 100%
						height 64px
						box-shadow 0 0 0 1px #ccc
						img
							display block
							width 100%
							height auto
							vertical-align middle
				.right
					width calc(100% - 80px)
					border-left 1px solid #FF9677
					padding 0 0 0 16px
					font-size 1.6rem
					line-height 22px
					text-transform uppercase
					.title
						margin 0 0 8px 0
						color red
						font-weight 700
					.author, .date
						margin 0 0 4px 0
						color #5D6064
					.button
						width 100%
						height 40px
						background-color #FFC758
						border-radius 8px
						box-shadow 0px 4px 1px alpha(#FFC758, 60%)
						cursor pointer
						line-height @height
						text-align center
						color #fff
						user-select none
						&:active
							transform translateY(4px)
							box-shadow none
			.show-more
				text-align center
				.button
					display inline-block
					height 40px
					padding 0 16px
					border-radius 6px
					text-align center
					background-color red
					box-shadow 0px 4px 1px darken(red, 25%)
					cursor pointer
					line-height @height
					text-transform uppercase
					color #fff
					font-size 1.4rem
					font-weight 700
					user-select none
					&:active
						transform translateY(4px)
						box-shadow none

	.no-papers
		// padding 40px
		min-height 90%
		flex center center
		flex-direction column
		text-align center
		color #aaa
		user-select none
		.icon
			display inline-block
			width 64px
			height 64px
			margin 0 0 16px 0
			>>> svg
				width 100%
				height 100%
			&:after
				content ''
				position absolute
				left -25%
				top 50%
				transform rotate(-45deg)
				width 150%
				height 3px
				border-radius 3px
				background-color @color
				box-shadow 0 3px 0 0 #fff
		p
			margin 0 auto
			max-width 250px
			text-align center
			text-transform uppercase
			font-size 1.6rem
			line-height 1.4em
			font-weight 700
	.loading
		absolute 0
		background alpha(#fff, 50%) url(../../../assets/img/button-loader-dark.svg) center center no-repeat
		background-size 64px

</style>
