<template>
	<div class="research-papers">
		<div class="research-papers-bg" v-hammer:tap="() => goto({ name: 'resources' })"></div>
		<div class="research-papers-content">
			<div class="header">
				<div class="tabs" :class="tab">
					<div class="tab papers" :class="{ active: tab === 'papers' }" v-hammer:tap="() => tab = 'papers'">
						<div class="icon"></div>
						{{ $t('research_papers_documents') }}
					</div>
					<div class="tab search" :class="{ active: tab === 'search' }" v-hammer:tap="() => tab = 'search'">
						<div class="icon"></div>
						{{ $t('search') }}
					</div>
				</div>
				<div class="close" v-hammer:tap="() => goto({ name: 'resources' })"></div>
			</div>

			<div class="title" :class="tab">
				<span v-if="tab === 'papers'">{{ $t('newest_first') }}</span>
				<span v-if="tab === 'search'">{{ $t('search_engine') }}</span>
			</div>

			<papers v-if="tab === 'papers'" class="content" />
			<tab-search v-if="tab === 'search'" class="content" @change-tab="change_tab" />

			<transition name="loading" class="content">
				<div v-if="loading" class="loading">
					<div class="loading-content">
						<div class="planet"></div>
						<div class="loader"></div>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Papers from '@/components/resources/research-papers/papers'
import TabSearch from '@/components/resources/research-papers/tab-search'

export default {
	name: 'research-papers',
	data() {
		return {
			loading: true,
			tab: 'papers'
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
	},
	methods: {
		goto(data) {
			this.$router.push(data)
		},
		change_tab(data) {
			this.tab = data.tab
		}
	},
	mounted() {
		setTimeout(() => {
			this.loading = false
		}, 2000)
	},
	components: { Papers, TabSearch }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.research-papers
		.research-papers-content
			.player-profile, .loading
				height calc(100% - 96px)



.research-papers
	darkblue = #61789E
	absolute 0
	flex center center
	transition 0.25s easeOutQuart
	&.research-papers-leave-to
		opacity 0
	&.research-papers-enter
		opacity 0
		.research-papers-content
			transform translateY(-48px)
	.research-papers-bg
		absolute 0
		background-color alpha(dark, 50%)
	.research-papers-content
		width 100%
		height 100%
		max-width 450px
		max-height 700px
		padding 8px 0 0 0
		transition 0.25s easeOutQuart
		// overflow hidden
		.header
			height 40px
			.tabs
				display block
				height 100%
				width 100%
				padding 0 8px
				&.papers:before
					background-color yellow
				&.search:before
					background-color red
				&:before
					content ''
					position absolute
					left 0
					right 0
					bottom 0
					height 4px
				.tab
					display inline-block
					height 100%
					padding 0 12px
					margin 0 4px 0 0
					background-color dark
					border-top-left-radius 8px
					border-top-right-radius 8px
					line-height 40px
					color #fff
					font-weight 700
					cursor pointer
					user-select none
					&.papers
						background-color red
						.icon
							background-image url(../../assets/img/profile/papers.svg)
					&.search
						background-color yellow
						.icon
							background-image url(../../assets/img/profile/search.svg)
					&.active
						cursor default
					.icon
						float left
						width 24px
						height 40px
						margin 0 8px 0 0
						background center center no-repeat
						background-size 20px auto
			.close
				position absolute
				right 8px
				top -2px
				width 56px
				height 32px
				background dark url(../../assets/img/profile/close-profile.svg) center center no-repeat
				box-shadow 0 0 0 2px #fff
				background-size 24px auto
				border-radius 8px
				cursor pointer
				&.back
					background-image url(../../assets/img/profile/back.svg)
					background-size 20px auto

		.title
			height 48px
			padding 16px
			line-height (@height - 32px)
			color #fff
			&.papers
				background-color red
			&.search
				background-color yellow

		.content
			height calc(100% - 40px)
			box-shadow 2px 6px 6px alpha(#000, 25%)

		.loading
			position absolute
			left 0
			right 0
			bottom 0
			top 96px
			height calc(100% - 48px)
			transition 0.25s easeOutQuart
			overflow hidden
			box-shadow 2px 6px 6px alpha(#000, 25%)
			&.loading-leave-to
				.loading-content
					transform translateY(100%)
			.loading-content
				height 100%
				flex center center
				flex-direction column
				background-color #61789E
				transition 0.25s easeOutQuart
				.planet
					width 120px
					height 120px
					background url(../../assets/img/profile/planet.svg) center center no-repeat
					background-size contain
				.loader
					margin 24px 0 0 0
					width 120px
					height 16px
					background url(../../assets/img/profile/loader.svg) center center no-repeat
					background-size contain

</style>
