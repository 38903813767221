<template>
	<div class="view-resources">
		<resources @loaded="$emit('loaded')" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Resources from '@/components/resources'

export default {
	name: 'view-resources',
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([ 'app' ])
	},
	components: { Resources }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'

.view-resources
	height 100%
	width 100%
	transition 0.25s easeOutQuart
	transition-delay 0.35s

</style>
