<template>
	<div v-if="paper && paper.document" class="paper">
		<div class="left">
			<div v-if="logo && logo.url && logo.document && logo.document.title" class="img">
				<img :src="logo.url" :alt="logo.document.title" :title="logo.document.title">
			</div>
		</div>
		<div class="right">
			<div v-if="paper.document.title" class="title">{{ paper.document.title }}</div>
			<div v-if="paper.document.author" class="author">By {{ paper.document.author }}</div>
			<div v-if="paper.document.published_on" class="date">{{ $t('published_on') }} {{ published_on }}</div>

			<div v-if="pdf" class="button" v-fancybox="{ src: pdf.url }" v-hammer:tap="() => open_url(paper.externalUrl)">{{ $t('view_research_paper') }}</div>
			<div v-if="!pdf" class="button" v-hammer:tap="() => open_url(paper.externalUrl)">{{ $t('view_research_paper') }}</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import { format } from 'date-fns'

export default {
	name: 'paper',
	data() {
		return {

		}
	},
	props: {
		paper: { default: null }
	},
	computed: {
		...mapGetters([ 'app' ]),
		published_on() {
			let d = new Date(Date.parse(this.paper.document.published_on))
			return d.toISOString().split('T')[0]
		},
		logo() {
			if (this.paper && this.paper.clientMedias) {
				const media = this.paper.clientMedias.find((m) => m.tags.includes('logo'))
				if (media) return media
			}
			return null
		},
		pdf() {
			if (this.paper && this.paper.clientMedias) {
				const media = this.paper.clientMedias.find((m) => { return m.tags.includes('research_paper') && m.tags.includes('pdf') })
				if (media) return media
			}
			return null
		}
	},
	methods: {
		save() {
			Apollo.mutate('OPEN_CLIENT_FILE', { clientFileId: this.paper.id })
		},
		open_url(url) {
			if (url) {
				let win = window.open(url, '_blank')
				win.focus()
			}
			this.save()
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.paper
	flex center flex-start
	padding 16px
	margin 0 0 16px 0
	border 2px solid #FF9677
	border-top-width 16px
	&:last-child
		margin-bottom 0
	.left
		width 80px
		padding-right 16px
		font-size 1rem
		.img
			width 100%
			height 64px
			// box-shadow 0 0 0 1px #ccc
			img
				display block
				width 100%
				height auto
				vertical-align middle
	.right
		width calc(100% - 80px)
		border-left 1px solid #FF9677
		padding 0 0 0 16px
		font-size 1.6rem
		line-height 22px
		text-transform uppercase
		.title
			margin 0 0 8px 0
			color red
			font-weight 700
		.author, .date
			margin 0 0 4px 0
			color #5D6064
		.button
			width 100%
			height 40px
			background-color #FFC758
			border-radius 8px
			box-shadow 0px 4px 1px alpha(#FFC758, 60%)
			cursor pointer
			line-height @height
			text-align center
			color #fff
			user-select none
			&:active
				transform translateY(4px)
				box-shadow none


</style>
