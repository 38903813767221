<template>
	<div class="tab-search">
		<div class="tab-content" v-scrollbar>
			<div>
				<form class="form" @submit.prevent="submit()">
					<div class="line">
						<label for="name" class="label">{{ $t('research_paper_name') }}</label>
						<input type="text" class="input" placeholder="No preferences" id="name" v-model="name">
					</div>
					<div class="line">
						<label for="author" class="label">{{ $t('author_name') }}</label>
						<input type="text" class="input" placeholder="No preferences" id="author" v-model="author">
					</div>
					<div class="line">
						<label for="published_after" class="label">{{ $t('publication_date') }}</label>
						<div class="inputs">
							<div>
								<label for="published_after" class="small-label">{{ $t('from') }} :</label>
								<input type="date" class="input" :class="{ empty: !published_after }" placeholder="From" id="published_after" v-model="published_after">
							</div>
							<div>
								<label for="published_before" class="small-label">{{ $t('to') }} :</label>
								<input type="date" class="input" :class="{ empty: !published_before }" placeholder="To" id="published_before" v-model="published_before">
							</div>
						</div>
					</div>
					<div class="buttons">
						<div class="button reset" v-hammer:tap="() => reset()">{{ $t('reset') }}</div>
						<button type="submit" class="button submit">{{ $t('submit') }}</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Apollo from '@/apollo/apollo-client'

export default {
	name: 'tab-search',
	computed: {
		...mapGetters([ 'app' ]),
		name: {
			get() { return this.app.research_papers_form.name.value },
			set(value) { return this.$store.commit('EDIT_RESEARCH_PAPERS_FORM', {
				name: { ...this.app.research_papers_form.name, value } })
			}
		},
		author: {
			get() { return this.app.research_papers_form.author.value },
			set(value) { return this.$store.commit('EDIT_RESEARCH_PAPERS_FORM', {
				author: { ...this.app.research_papers_form.author, value } })
			}
		},
		published_after: {
			get() { return this.app.research_papers_form.published_after.value },
			set(value) { return this.$store.commit('EDIT_RESEARCH_PAPERS_FORM', {
				published_after: { ...this.app.research_papers_form.published_after, value } })
			}
		},
		published_before: {
			get() { return this.app.research_papers_form.published_before.value },
			set(value) { return this.$store.commit('EDIT_RESEARCH_PAPERS_FORM', {
				published_before: { ...this.app.research_papers_form.published_before, value } })
			}
		},
	},
	methods: {
		reset() {
			this.name = ''
			this.author = ''
			this.published_before = ''
			this.published_after = ''
		},
		submit() {
			this.$emit('change-tab', { tab: 'papers' })
		}
	},
	async created() {
		const fields = Object.keys(this.app.research_papers_form)
		let filters = await Apollo.query('CLIENT_FILTER_BY', { targetModel: 'Client::File' })
		if (filters) {
			filters.forEach((filter) => {
				filter.tags.forEach((tag) => {
					if (fields.includes(tag)) {
						let values = {}
						values[tag] = { ...this.app.research_papers_form[tag], filter_id: filter.id }
						this.$store.commit('EDIT_RESEARCH_PAPERS_FORM', values)
					}
				})
			})
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.tab-search
	.tab-content
		height 100%
		background-color #fff
		.form
			padding 32px
			.line
				margin 0 0 16px 0
				.inputs
					flex center center
					> div
						width calc(50% - 4px)
						&:first-child
							margin 0 4px 0 0
						&:last-child
							margin 0 0 0 4px
				.label
					display block
					padding-left 12px
					width 100%
					margin 0 0 2px 0
					font-weight 700
				.small-label
					display block
					padding-left 12px
					width 100%
					margin 0 0 2px 0
					font-size 1.4rem
					color #999
				.input
					display block
					width 100%
					height 36px
					padding 1px 12px 0 12px
					border 1px solid yellow
					font inherit
					color dark
					outline none
					border-radius 32px
					appearance none
					line-height (@height - 4px)
					&:focus
						box-shadow 0 0 0 1px yellow
						background-color lighten(yellow, 90%)
					&.empty
						color #aaa
				select.input
					appearance none
					background-image url(../../../assets/img/chevron-down.svg)
					background-position right 12px center
					background-size 24px
					background-repeat no-repeat
					&.empty
						color #aaa
			.buttons
				flex center center
				padding-top 16px
				.button
					height 48px
					margin 0 4px
					padding 0 16px
					border-radius 16px
					background-color #61789E
					border none
					outline none
					cursor pointer
					font inherit
					color #fff
					line-height @height
					font-weight 700
					&:active
						transform translateY(2px)


::placeholder
	color #aaa
	opacity 1
	user-select none

</style>
